import { Action } from '../../../../../store';

const succeeded = (): Action => ({
  type: 'itcListDrawing/succeeded'
});

const failed = (payload): Action => ({
  type: 'itcListDrawing/failed',
  payload
});

const ItcListDrawingAction = {
  succeeded,
  failed
};

export { ItcListDrawingAction };
