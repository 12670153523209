import { DsHeader } from '@obenyahya/design-system';
import { DsNavbar } from '@obenyahya/design-system';

import { ItcDateFilter } from '../../itc-date-filter';
import { ItcFileReader } from '../../itc-extractor';
import { ItcListDrawer } from '../../itc-drawer';

import { Logo } from '../components/logo';

export const Header = () => {
  return (
    <DsHeader
      color="primary"
      leftSide={<Logo />}
      rightSide={
        <DsNavbar>
          <ItcListDrawer />
          <ItcDateFilter />
          <ItcFileReader />
        </DsNavbar>
      }
    ></DsHeader>
  );
};
