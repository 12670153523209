import { Action } from '../../../../../store';

const isInProgress = (): Action => ({ type: 'xlsxReading/inProgress' });

const succeeded = (payload): Action => ({
  type: 'xlsxReading/succeeded',
  payload
});

const failed = (payload): Action => ({ type: 'xlsxReading/failed', payload });

const XlsxReadingAction = {
  isInProgress,
  succeeded,
  failed
};

export { XlsxReadingAction };
