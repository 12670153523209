import * as XLSX from 'xlsx';

import { ItcXlsxConverterV1 } from './itc-xlsx-converter-v1';

const attributes = [
  'sousChantierName',
  'sousChantierDescription',
  'details.familleDeChantierSousProjet',
  'details.typeFenêtre',
  'localisation',
  'details.descriptifRessource',
  'sousChantierCode',
  'details.weekend',
  'details.nbSemImpactees',
  'details.blocNoteRessource',
  'startPkStartTime',
  'startPkEndTime',
  'endPkStartTime',
  'endPkEndTime',
  'calendar'
];
const beginParsingFromRow = 'Sous-chantier';

const parse = (res: FileReader[]) => {
  return res.flatMap((file) => parseOneFile(file));
};

const parseOneFile = (res: FileReader) => {
  const { xlsxHeader, xlsxBody } = extractRows(res, beginParsingFromRow);
  if (!xlsxBody.length || !xlsxHeader.length) {
    return [];
  }
  const matrix = xlsxBody.map((row) => row.split(/;/));
  const rowAsObjectList = matrix.map((vect) => mapValueToAttribute(vect));
  const validRowList = rowAsObjectList.filter(
    (object) => object && object.localisation
  );
  const itcDtoList = ItcXlsxConverterV1.getItcs(
    xlsxHeader,
    validRowList,
    xlsxBody
  );
  return itcDtoList;
};

const extractRows = (res: FileReader, beginFrom: string) => {
  if (!res || !res.result) {
    throw new Error("Can't extract data from an invalid xlsx file");
  }

  const xlsxAsString = readXlsx(res);
  const beginningIndex = xlsxAsString.search(beginFrom);
  const table = xlsxAsString.slice(beginningIndex);
  const [xlsxHeader, ...xlsxBody] = (table as string).split(/\|/);

  return { xlsxHeader, xlsxBody };
};

const readXlsx = (fileReader: FileReader) => {
  const wb = XLSX.read(fileReader.result, { type: 'binary' });
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  return XLSX.utils.sheet_to_csv(ws, { FS: ';', RS: '|' });
};

const mapValueToAttribute = (vect: string[]) => {
  const object = vect.reduce(
    (acc, value, index) => {
      if ((!value || value === ' ') && index >= attributes.length - 1) {
        value = '0';
      }
      if (index >= attributes.length) {
        index = attributes.length - 1;

        return {
          ...acc,
          ...{
            [attributes[index]]: `${acc[attributes[index]]}${value}`
          }
        };
      }
      return { ...acc, ...{ [attributes[index]]: value } };
    },
    { localisation: '' }
  );
  return object;
};

const ItcXlsxParserV1 = {
  parse,
  mapValueToAttribute
};

export { ItcXlsxParserV1 };
