import { SynoptiqueFetchingAction } from '../actions/synoptique-fetching.action';
import { State, Action, Status } from '../../../../../store';

const initialState = { status: Status.NotPerformed, value: '' };
export const SynoptiqueFetchingReducer = (
  state: State = initialState,
  action: Action
): State => {
  const { type, payload } = action;
  switch (type) {
    case SynoptiqueFetchingAction.succeeded(payload).type:
      return {
        ...state,
        ...{ status: Status.Succeeded, value: payload }
      };

    case SynoptiqueFetchingAction.failed(payload).type:
      return {
        ...state,
        ...{ status: Status.Failed, value: payload }
      };

    default:
      return state;
  }
};
