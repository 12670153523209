import { Segment } from './segment';
import { Quai } from './quai.interface';
import { Ligne } from './ligne.enum';
import { Infra } from './infra.interface';
import { Drawer } from '../../ports';

export interface Voie extends Infra {
  name: string;
  segmentList: Segment[];
  quaiList?: Quai[];
  ligneList: Ligne[];
}

const draw = (voie: Voie) => {
  const segmentListWithStyle = applyVoieStyleToSegmentList(voie);

  Drawer.drawLines(segmentListWithStyle);
};

const applyVoieStyleToSegmentList = (voie: Voie): Segment[] => {
  const segmentListWithStyle = voie.segmentList.map((segment) => ({
    ...segment,
    ...{ style: voie.style }
  }));
  return segmentListWithStyle;
};

const VoieFactory = { draw };

export { VoieFactory };
