import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ItcFactory } from '../../../../domaine/itc';
import { Status } from '../../../../store';
import { ItcListDrawingAction } from '../store/actions/itc-list-drawing.action';

export const ItcListDrawer = () => {
  const dispatch = useDispatch();
  const itcList = useSelector((state: any) => state.itcList);
  const itcListFiltredByDate = useSelector(
    (state: any) => state.itcListFiltredByDate
  );
  const synoptiqueA = useSelector((state: any) => state.synoptique);

  const stateIsReady = (state) =>
    state && state.value && state.status === Status.Succeeded;

  useEffect(() => {
    try {
      if (stateIsReady(itcList) && stateIsReady(synoptiqueA)) {
        ItcFactory.draw(itcList.value, synoptiqueA.value);
        dispatch(ItcListDrawingAction.succeeded());
      }
    } catch (error) {
      dispatch(ItcListDrawingAction.failed(error));
    }
  }, [itcList, synoptiqueA, dispatch]);

  useEffect(() => {
    try {
      if (stateIsReady(itcListFiltredByDate) && stateIsReady(synoptiqueA)) {
        ItcFactory.eraseAll();
        ItcFactory.draw(itcListFiltredByDate.value, synoptiqueA.value);
        dispatch(ItcListDrawingAction.succeeded());
      }
    } catch (error) {
      dispatch(ItcListDrawingAction.failed(error));
    }
  }, [itcListFiltredByDate, synoptiqueA, dispatch]);

  return <></>;
};
