import { Action } from '../../../../../store';

const succeeded = (payload): Action => ({
  type: 'synoptiqueFetching/succeeded',
  payload
});

const failed = (payload): Action => ({
  type: 'synoptiqueFetching/failed',
  payload
});

const SynoptiqueFetchingAction = {
  succeeded,
  failed
};

export { SynoptiqueFetchingAction };
