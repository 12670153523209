import { ItcDateFilteringAction } from '../actions/itc-date-filtering.action';
import { State, Action, Status } from '../../../../../store';

const initialState = { status: Status.NotPerformed, value: '' };
export const ItcDateFilteringReducer = (
  state: State = initialState,
  action: Action
): State => {
  const { type, payload } = action;
  switch (type) {
    case ItcDateFilteringAction.succeeded(payload).type:
      return {
        ...state,
        ...{ status: Status.Succeeded, value: payload }
      };

    case ItcDateFilteringAction.failed(payload).type:
      return {
        ...state,
        ...{ status: Status.Failed, value: payload }
      };

    default:
      return state;
  }
};
