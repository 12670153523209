import { useDispatch } from 'react-redux';

import { Reader } from '../../../../ports';
import { XlsxReadingAction } from '../store/actions/xlsx-reading.action';
import { ItcFileButton } from '../components/itc-file-button';
import { ItcListExtractor } from './itc-list-extractor';

export const ItcFileReader = () => {
  const dispatch = useDispatch();

  const displayItcs = (event: any) => {
    dispatch(XlsxReadingAction.isInProgress());
    const files = event.target.files;

    let xlsxFilePromiseList = [];
    for (let file of files) {
      xlsxFilePromiseList.push(Reader.readXlsxFile(file));
    }

    Promise.all(xlsxFilePromiseList).then(
      (readerList: FileReader[]) => {
        if (readerList.every((reader) => !reader.result)) {
          dispatch(XlsxReadingAction.failed('can not read files'));
        }
        const flattedreaderList = readerList.flatMap((reader) => reader);
        dispatch(XlsxReadingAction.succeeded(flattedreaderList));
      },
      (err: FileReader) => {
        dispatch(XlsxReadingAction.failed(err));
      }
    );
  };

  return (
    <>
      <ItcFileButton onChooseItcFiles={(event) => displayItcs(event)} />
      <ItcListExtractor />
    </>
  );
};
