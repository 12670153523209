import moment from 'moment';
import { ItcDto } from '../../ports';

const getStartingDate = (header, rows) => {
  const monthNames = [
    'JAN',
    'FEV',
    'MAR',
    'MAI',
    'AVR',
    'JUN',
    'JUL',
    'AOU',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ];
  const [monthsRow] = rows.filter((row) =>
    row
      .split(';')
      .filter((cell) => cell)
      .every((cell) => monthNames.includes(cell))
  );
  const JanIndex = monthsRow
    .split(';')
    .findIndex((cell) => cell && cell === 'JAN');

  const startingDateNumber =
    JanIndex > 15 ? 31 - (JanIndex - 15) : 15 - JanIndex;
  const startingMonth = JanIndex > 15 ? '12' : '1';
  const [startingYear] = header.split(';').slice(14);
  return `${startingMonth}/${startingDateNumber}/${startingYear}`;
};

const getCalendarAsDateList = (header, calendar, xlsxBody) => {
  const startingDate = getStartingDate(header, xlsxBody);
  const dateList = [];
  calendar.split('').forEach((day, index) => {
    if (day === '1') {
      const date = moment(startingDate).add(index, 'd').format('MM/DD/YYYY');
      return dateList.push(date);
    }
  });
  return dateList;
};

const getItcs = (header, rows, xlsxBody): ItcDto[] => {
  const itcs = rows.map((elm: any) => {
    const {
      sousChantierName,
      sousChantierCode,
      calendar,
      startPkStartTime,
      endPkEndTime,
      localisation
    } = elm;
    const calendarAsDateList = getCalendarAsDateList(
      header,
      calendar,
      xlsxBody
    );
    let localisationList = [];
    if (localisation) {
      const localisationParts = localisation.split(',');
      localisationList = localisationParts.map((local: string) => {
        const [ligneInfraNumber, voieAndPks] = local.split(' ');
        const [voieName, pks] = voieAndPks.split(/[[\]]/);
        const [startPointKm, endPointKm] = pks
          .split('_')
          .map((pk: any) => pk.replace('+', ''));
        return { ligneInfraNumber, voieName, startPointKm, endPointKm };
      });
    }
    return {
      reference: `${sousChantierName}_${sousChantierCode}`,
      sousChantierName,
      timeInterval: { start: startPkStartTime, end: endPkEndTime },
      daysInterval: calendarAsDateList,
      localisationList
    };
  });
  return itcs;
};

const ItcXlsxConverterV1 = {
  getItcs
};

export { ItcXlsxConverterV1 };
