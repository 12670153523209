import { DrawerAdapter } from '../adapters';
import { Animation } from './interfaces/animation';
import { LabelDto } from './interfaces/label-dto.interface';
import { LineDto } from './interfaces/line-dto.interface';
import { PointDto } from './interfaces/point-dto.interface';
import { RectangleDto } from './interfaces/rectangle-dto.interface';
import { TooltipDto } from './interfaces/tootltip-dto.interface';

const drawLines = (
  lines: LineDto[],
  animation?: Animation,
  tooltip?: TooltipDto,
  label?: LabelDto
) => DrawerAdapter.drawLines(lines, animation, tooltip, label);

const drawLine = (line: LineDto, label?: LabelDto) =>
  DrawerAdapter.drawLine(line, label);

const eraseElement = (id: string) => DrawerAdapter.eraseElement(id);

const drawPolyline = (
  pointList: PointDto[],
  animation?: Animation,
  tooltip?: TooltipDto,
  label?: LabelDto
) => {
  DrawerAdapter.drawPolyline(pointList, animation, tooltip, label);
};

const drawRectangle = (rectangle: RectangleDto, label?: LabelDto) =>
  DrawerAdapter.drawRectangle(rectangle, label);

const initDrawingArea = (
  selector: string,
  drawingAreaWidth: string,
  drawingAreaHeight: string
) => {
  return DrawerAdapter.initSvg(selector, drawingAreaWidth, drawingAreaHeight);
};

const Drawer = {
  eraseElement,
  initDrawingArea,
  drawLine,
  drawPolyline,
  drawRectangle,
  drawLines
};

export { Drawer };
