import { Action } from '../../../../../store';

const succeeded = (payload): Action => ({
  type: 'xlsxItcExtracting/succeeded',
  payload
});

const failed = (payload): Action => ({
  type: 'xlsxItcExtracting/failed',
  payload
});

const XlsxItcExtractingAction = {
  succeeded,
  failed
};

export { XlsxItcExtractingAction };
