import { Action } from '../../../../../store';

const succeeded = (payload): Action => ({
  type: 'itcDateFiltering/succeeded',
  payload
});

const failed = (payload): Action => ({
  type: 'itcDateFiltering/failed',
  payload
});

const ItcDateFilteringAction = {
  succeeded,
  failed
};

export { ItcDateFilteringAction };
