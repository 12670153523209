import { Infra } from './infra.interface';
import { LigneInfra, LigneInfraFactory } from './ligne-infra';

export interface Synoptique extends Infra {
  name: string;
  ligneInfraList: LigneInfra[];
}

const draw = (synoptique: Synoptique) => {
  const { ligneInfraList } = synoptique;
  ligneInfraList.forEach((ligneInfra) => LigneInfraFactory.draw(ligneInfra));
};

const SynoptiqueFactory = { draw };

export { SynoptiqueFactory };
