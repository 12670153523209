import { Infra } from './infra.interface';
import { Station, StationFactory } from './station';
import { Voie, VoieFactory } from './voie';

export interface LigneInfra extends Infra {
  number: string;
  voieList: Voie[];
  stationList: Station[];
}

const draw = (ligneInfra: LigneInfra) => {
  const { voieList, stationList } = ligneInfra;
  voieList.forEach((voie) => VoieFactory.draw(voie));
  stationList.forEach((station) => StationFactory.draw(station));
};

const LigneInfraFactory = { draw };

export { LigneInfraFactory };
