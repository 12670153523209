import { DsInputFile } from '@obenyahya/design-system';

interface ItcFileButtonProps {
  onChooseItcFiles;
}

export const ItcFileButton = (props: ItcFileButtonProps) => {
  const { onChooseItcFiles } = props;

  return (
    <DsInputFile
      onSelectFiles={onChooseItcFiles}
      multiple={true}
      accept=".csv, .xlsx"
    >
      importer des itc
    </DsInputFile>
  );
};
