import { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DsButton } from '@obenyahya/design-system';

interface DateRangePicker {
  onDateRangePicked;
}

export const RangeDatePicker = (props: DateRangePicker) => {
  const { onDateRangePicked } = props;
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const filter = (dates) => {
    const [startDate, endDate] = dates;
    setDateRange([startDate, endDate]);
    onDateRangePicked(startDate, endDate);
  };

  const StartDateInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <DsButton onClick={onClick} data-ref={ref}>
      {value || 'Filtrer par date'}
    </DsButton>
  ));

  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(dates) => filter(dates)}
      customInput={<StartDateInput />}
      withPortal
      fixedHeight
      dateFormat="dd/MM/yyyy"
      showWeekNumbers
      showYearDropdown
      isClearable
    />
  );
};
