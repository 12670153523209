import { Itc } from '../../../../domaine/itc';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

export const filterByDateRange = (itcList: Itc[], dateRange: DateRange) => {
  const { startDate, endDate } = dateRange;
  if (startDate && endDate) {
    if (Array.isArray(itcList)) {
      const filtredItcList = itcList.filter((itc) => {
        return itc.daysInterval.some((itcDate) => {
          const date = new Date(itcDate);
          return startDate <= date && date <= endDate;
        });
      });
      return filtredItcList;
    }
    throw new Error(`itc list isn't an array`);
  }
  if (!startDate && !endDate) {
    return itcList;
  }
  throw new Error('range dates are undefined');
};
