import { Action } from '../../../../../store';

const succeeded = (): Action => ({
  type: 'synoptiqueDrawing/succeeded'
});

const failed = (payload): Action => ({
  type: 'synoptiqueDrawing/failed',
  payload
});

const SynoptiqueDrawingAction = {
  succeeded,
  failed
};

export { SynoptiqueDrawingAction };
