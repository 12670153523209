import { TimeInterval } from './time-interval.interface';
import { Localisation } from './localisation';
import { Synoptique } from '../synoptique';
import {
  Animation,
  AnimationEvent,
  DrawColor,
  Drawer,
  DrawWidth,
  LabelDto,
  PointDto
} from '../../ports';
import { TooltipDto } from '../../ports/interfaces/tootltip-dto.interface';
import travauxImage from '../assets/travaux.png';
import { Segment } from '../synoptique/segment';

export interface Itc {
  reference: string;
  localisationList: Localisation[];
  timeInterval: TimeInterval;
  daysInterval: string[];
  sousChantierName: string;
}

interface ExtendedSegment extends Segment {
  ligneInfraNumber: string;
  voieName: string;
}

const itcStyle = {
  width: DrawWidth.secondary,
  color: DrawColor.secondary
};

const create = (
  reference: string,
  localisationList: Localisation[],
  timeInterval: TimeInterval,
  daysInterval: string[],
  sousChantierName: string
): Itc => ({
  reference,
  timeInterval,
  daysInterval,
  sousChantierName,
  localisationList
});

const draw = (itcList: Itc[], synoptique: Synoptique) => {
  const synoptiqueSegmentList = getSynoptiqueSegmentList(synoptique);
  itcList.forEach((itc) => {
    const itcLocationList = getItcSegmentsList(itc, synoptiqueSegmentList);
    const selector = `itc_${itc.reference.replace(' ', '')}`;
    const itcLocationSegmentList = itcLocationList
      .flatMap((segment) => segment)
      .map((segment) => ({
        ...segment,
        ...{ id: selector }
      }));

    if (itcLocationSegmentList.length > 0) {
      const middelIndex = Math.round(itcLocationSegmentList.length / 2) - 1;
      const { x, y, pk } = itcLocationSegmentList[middelIndex].startPoint;

      const position = { x: x - 15, y, pk };

      const label = getLabel(travauxImage, position);
      const tooltip = getTooltip(itc, position);
      const style = {
        fromStyle: itcStyle,
        toStyle: { width: DrawWidth.tertiary }
      };
      const animation: Animation = { type: AnimationEvent.hover, style };
      Drawer.drawLines(itcLocationSegmentList, animation, tooltip, label);
    }
  });
};

const getLabel = (image: string, position): LabelDto => ({
  image,
  position
});

const getTooltip = (itc: Itc, position: PointDto): TooltipDto => ({
  content: [
    `horraire: ${Object.values(itc.timeInterval)}`,
    `Jours: ${itc.daysInterval.join('-')}`,
    `Localisations: ${itc.localisationList.map((localisation) => {
      const { ligneInfraNumber, voieName, pkStart, pkEnd } = localisation;
      return `${ligneInfraNumber} ${voieName}(${pkStart} - ${pkEnd})`;
    })}`,
    `Sous chantier: ${itc.sousChantierName}`,
    `Réference: ${itc.reference.replace(`${itc.sousChantierName}_`, '')}`
  ],
  position: { ...position, ...{ x: position.x + 20, y: position.y + 20 } }
});

const eraseAll = () => {
  Drawer.eraseElement('[id^=itc]');
  Drawer.eraseElement('[id^=label]');
};

const getSynoptiqueSegmentList = (
  synoptique: Synoptique
): ExtendedSegment[] => {
  const { ligneInfraList } = synoptique;
  return ligneInfraList.flatMap(({ number, voieList }) => {
    return voieList.flatMap(({ name, segmentList }) => {
      return segmentList.map((segment) => ({
        ...segment,
        ...{ ligneInfraNumber: number, voieName: name }
      }));
    });
  });
};

const getItcSegmentsList = (
  itc: Itc,
  synoptiqueSegmentList: ExtendedSegment[]
): Segment[][] => {
  const itcSegmentList = itc.localisationList.map((localisation) => {
    const localisationSegmentList = synoptiqueSegmentList.map((segment) => {
      const { ligneInfraNumber, voieName, startPoint, endPoint } = segment;
      if (
        ligneInfraNumber === localisation.ligneInfraNumber &&
        voieName === localisation.voieName
      ) {
        if (
          localisation.pkStart <= startPoint.pk &&
          endPoint.pk <= localisation.pkEnd
        ) {
          return segment;
        }
        if (
          startPoint.pk < localisation.pkStart &&
          localisation.pkStart < endPoint.pk
        ) {
          const scaleStart = Math.abs(
            (endPoint.pk - localisation.pkStart) / (endPoint.pk - startPoint.pk)
          );
          const x = endPoint.x - (endPoint.x - startPoint.x) * scaleStart;
          const y = endPoint.y - (endPoint.y - startPoint.y) * scaleStart;

          const newSegment: Segment = {
            startPoint: {
              x,
              y,
              pk: localisation.pkStart
            },
            endPoint
          };
          return newSegment;
        }
        if (
          startPoint.pk < localisation.pkEnd &&
          localisation.pkEnd < endPoint.pk
        ) {
          const scaleEnd = Math.abs(
            (localisation.pkEnd - startPoint.pk) / (endPoint.pk - startPoint.pk)
          );
          const x = (endPoint.x - startPoint.x) * scaleEnd + startPoint.x;
          const y = (endPoint.y - startPoint.y) * scaleEnd + startPoint.y;

          const newSegment: Segment = {
            startPoint,
            endPoint: {
              x,
              y,
              pk: localisation.pkEnd
            }
          };
          return newSegment;
        }
        return null;
      }
      return null;
    });

    const partitionList = localisationSegmentList
      .filter((segment) => segment)
      .flatMap(
        (segment) => ({ ...segment, ...{ style: itcStyle } }) // <----- style !
      );
    return partitionList;
  });
  return itcSegmentList;
};

const ItcFactory = {
  draw,
  eraseAll,
  create
};

export { ItcFactory };
