import * as d3 from 'd3';

import {
  TooltipDto,
  LabelDto,
  LineDto,
  PointDto,
  RectangleDto,
  AnimationEffectStyle,
  Animation,
  AnimationEvent,
  TextColor,
  TextWeight,
  TextSize,
  TextOrientation,
  DrawColor,
  DrawWidth
} from '../../ports';

let selection;
let zoomTransform;

const eraseElement = (id: string) => {
  if (selection && selection.selectAll(`[id^=itc]`)) {
    return selection.selectAll(id).remove();
  }
};

const drawLines = (
  lines: LineDto[],
  animation?: Animation,
  tooltip?: TooltipDto,
  label?: LabelDto
) => {
  if (selection && lines.length > 0) {
    const defaultColor = DrawColor.primary;
    const defaultWidth = DrawWidth.primary;
    const [line] = lines;
    const selector = line && line.id;
    const width = (line && line.style && line.style.width) || defaultWidth;
    const group = selection.append('g');
    applyAnimation(group, selector, animation, tooltip, label);
    lines.forEach(({ startPoint, endPoint, style }) =>
      group
        .append('line')
        .attr('x1', startPoint.x)
        .attr('y1', startPoint.y)
        .attr('x2', endPoint.x)
        .attr('y2', endPoint.y)
        .attr('id', selector)
        .attr('stroke-width', width)
        .style('stroke', (style && style.color) || defaultColor)
    );
    transformElement(`svg g`);
    return group;
  }
};

const drawLine = (line: LineDto, label?: LabelDto) => {
  const { startPoint, endPoint, style } = line;
  const defaultColor = DrawColor.primary;
  const defaultWidth = DrawWidth.primary;

  if (selection) {
    const group = selection.append('g');
    group
      .append('line')
      .attr('x1', startPoint.x)
      .attr('y1', startPoint.y)
      .attr('x2', endPoint.x)
      .attr('y2', endPoint.y)
      .attr('stroke-width', (style && style.width) || `${defaultWidth}px`)
      .style('stroke', (style && style.color) || defaultColor);

    applyAnimation(selection, 'line', null, null, label);
    return selection;
  }
  throw new Error("can't draw the line");
};

const drawPolyline = (
  pointList: PointDto[],
  animation?: Animation,
  tooltip?: TooltipDto,
  label?: LabelDto
) => {
  const defaultColor = DrawColor.primary;
  const defaultWidth = DrawColor.primary;
  const points = pointList.flatMap((point) => [point.x, point.y]);
  const [firstPoint] = pointList;
  const style = firstPoint && firstPoint.style;
  const selector = firstPoint && firstPoint.id;
  const width = (style && style.width) || defaultWidth;

  if (selection) {
    const group = selection.append('g');
    group
      .append('polyline')
      .attr('points', points)
      .attr('stroke-width', width)
      .attr('id', selector)
      .attr('fill', 'none')
      .style('stroke', (style && style.color) || defaultColor);
    applyAnimation(group, selector, animation, tooltip, label);
    transformElement(`svg g`);
    return group;
  }
  throw new Error("can't draw the polyline");
};

const drawRectangle = (rectangle: RectangleDto, label?: LabelDto) => {
  const { startPoint, width, height } = rectangle;
  if (selection) {
    selection
      .append('rect')
      .attr('x', startPoint.x)
      .attr('y', startPoint.y)
      .attr('width', width)
      .attr('height', height);

    applyAnimation(selection, 'rectangle', null, null, label);

    return selection;
  }
  throw new Error("can't draw the rectangle");
};

const displayTooltip = (tooltip: TooltipDto, toSelector: string) => {
  const { content, position } = tooltip;
  const { x, y } = position;

  if (selection) {
    const tooltipMaxHeight = Math.trunc(0.7 * content.join('').length);
    const selector = `tooltip-${toSelector}`;
    selection.append('svg').html(
      `<foreignObject id="${selector}" x="${x}" y="${y}" width="350" height="${tooltipMaxHeight}">
        ${getTooltip(content)}
        </foreignObject>`
    );
    transformElement(`svg svg #${selector}`);
    return selection;
  }
};

const transformElement = (selector) =>
  d3.selectAll(selector).attr('transform', zoomTransform);

const getTooltip = (
  contentList: string[]
) => `<div style="background: #E8E8E8; width: 350px; font-size: 0.65rem; z-index: 10">
<ul style="list-style-type: '-'; padding: 0.5rem; border: solid; border-width: 1px">
${contentList.reduce(
  (cml, content) => `<li style="margin: 0.1rem">${content}</li>` + cml,
  ''
)}
</ul>
</div>`;

const applyAnimation = (
  selectionElement: Element,
  selector: string,
  animation?: Animation,
  tooltip?: TooltipDto,
  label?: LabelDto
) => {
  if (animation && animation.type === AnimationEvent.hover) {
    const { style } = animation;
    applyHoverAnimation(selectionElement, selector, style, tooltip);
  }
  if (label) {
    addLabel(selectionElement, label, selector);
  }
};

const addLabel = (selectionElement: any, label: LabelDto, selector: string) => {
  const { text, image, position, style } = label;
  const defaultSize = TextSize.primary;
  const defaultFontWeight = TextWeight.primary;
  const defaultFontColor = TextColor.primary;
  const defaultTextOrientation = TextOrientation.primary;
  if (text) {
    const { x, y } = position;
    selectionElement
      .append('text')
      .attr('x', x)
      .attr('y', y)
      .style(
        'writing-mode',
        (style && style.textOrientation) || defaultTextOrientation
      )
      .style('font-size', `${(style && style.size) || defaultSize}rem`)
      .style('font-weight', (style && style.fontWeight) || defaultFontWeight)
      .style('fill', (style && style.fontColor) || defaultFontColor)
      .text(text)
      .attr('id', selector);
  }
  if (image && position) {
    const { x, y } = position;
    const labelId = `label-${selector}`;
    selectionElement.html(
      `<foreignObject  x="${x}" y="${y}" width="15" height="15" id="${labelId}">
        <img src="${image}" width="15"/>
      </foreignObject>`
    );
    return selectionElement;
  }
};

const initSvg = (
  selector: string,
  drawingAreaWidth: string,
  drawingAreaHeight: string
) => {
  selection = d3
    .select(selector)
    .append('svg')
    .attr('width', drawingAreaWidth)
    .attr('height', drawingAreaHeight);

  initZoom();
};

const initZoom = () => {
  let zoom = d3.zoom().scaleExtent([0.03, 1.5]).on('zoom', zoomOn);

  function zoomOn(event) {
    const { transform } = event;
    zoomTransform = transform;
    // d3.selectAll('svg').attr('transform', transform);
    d3.selectAll('svg g').attr('transform', transform);
    d3.selectAll('svg svg').attr('transform', transform);
    d3.selectAll('svg rect').attr('transform', transform);
    d3.selectAll('svg text').attr('transform', transform);
  }
  const zoomCoef = 0.045;
  const toX = -1200;
  const toY = -350;
  setTimeout(() => {
    zoom.scaleBy(d3.selectAll('svg'), zoomCoef, [toX, toY]);
    zoom.scaleBy(d3.selectAll('svg g'), zoomCoef, [toX, toY]);
    zoom.scaleBy(d3.selectAll('svg svg'), zoomCoef, [toX, toY]);
    zoom.scaleBy(d3.selectAll('svg rect'), zoomCoef, [toX, toY]);
    zoom.scaleBy(d3.selectAll('svg text'), zoomCoef, [toX, toY]);
  }, 100);

  return selection.call(zoom);
};

const applyHoverAnimation = (
  selectionElement: any,
  selector: string,
  effectStyle: AnimationEffectStyle,
  tooltip?: TooltipDto
) => {
  const { fromStyle, toStyle } = effectStyle;
  return selectionElement
    .on('mouseenter', function () {
      if (tooltip) {
        displayTooltip(tooltip, selector);
      }
      const { width } = toStyle;
      return d3
        .selectAll(this.querySelectorAll(`#${selector}`))
        .attr('stroke-width', width);
    })
    .on('mouseleave', function () {
      if (tooltip) {
        eraseElement(`#tooltip-${selector}`);
      }
      const { width } = fromStyle;
      return d3
        .selectAll(this.querySelectorAll(`#${selector}`))
        .attr('stroke-width', width);
    });
};

const DrawerAdapter = {
  eraseElement,
  initSvg,
  drawLine,
  drawPolyline,
  drawRectangle,
  drawLines
};

export { DrawerAdapter };
