const read = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = () => {
      resolve(reader);
    };
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });
};

const FileReaderAdapater = {
  read
};

export { FileReaderAdapater };
