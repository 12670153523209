import { ItcXlsxParser, ItcDto } from '../../../../ports';
import { Itc, ItcFactory, LocalisationFactory } from '../../../../domaine/itc';

export const createItcList = (xlsxData): Itc[] => {
  const itcXlsxData = ItcXlsxParser.parse(xlsxData);
  const itcList = itcXlsxData.map((itcXlsxElement: ItcDto) => {
    const {
      reference,
      sousChantierName,
      daysInterval,
      timeInterval,
      localisationList
    } = itcXlsxElement;
    const locationEntities = localisationList.map((localisation) => {
      const { ligneInfraNumber, voieName, startPointKm, endPointKm } =
        localisation;
      return LocalisationFactory.create(
        ligneInfraNumber,
        voieName,
        startPointKm,
        endPointKm
      );
    });
    return ItcFactory.create(
      reference,
      locationEntities,
      timeInterval,
      daysInterval,
      sousChantierName
    );
  });
  return itcList;
};
