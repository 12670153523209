import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Status } from '../../../../store';
import { XlsxItcExtractingAction } from '../store/actions/xlsx-itc-extracting.action';
import { createItcList } from './itc-extraction-handler';

export const ItcListExtractor = () => {
  const dispatch = useDispatch();
  const xlsxData = useSelector((state: any) => state.xlsxData);

  useEffect(() => {
    if (xlsxData && xlsxData.status === Status.Succeeded) {
      const { value } = xlsxData;
      try {
        const itcList = createItcList(value);
        dispatch(XlsxItcExtractingAction.succeeded(itcList));
      } catch (error) {
        dispatch(XlsxItcExtractingAction.failed(error));
      }
    }
  }, [xlsxData, dispatch]);

  return <></>;
};
