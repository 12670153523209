import { Drawer } from '../../ports';
import { Line, Synoptique } from '../synoptique';

export interface Localisation {
  ligneInfraNumber: string;
  voieName: string;
  pkStart: number;
  pkEnd: number;
}

const create = (
  ligneInfraNumber: string,
  voieName: string,
  pkStart: number,
  pkEnd: number
) => ({
  ligneInfraNumber,
  voieName,
  pkStart,
  pkEnd
});

const draw = (localisationList: Localisation[], synoptique: Synoptique) => {
  const lines: Line[] = localisationList.map((localisation) => {
    return {
      startPoint: { x: localisation.pkStart, y: 0 },
      endPoint: { x: localisation.pkEnd, y: 0 }
    };
  });

  lines.forEach((line) => Drawer.drawLine(line));
};

const LocalisationFactory = {
  draw,
  create
};

export { LocalisationFactory };
