import { combineReducers } from 'redux';
import { XlsxItcExtractingReducer } from '../app/features/itc-extractor/store/reducers/xlsx-itc-extracting.reducer';
import { XlsxReadingReducer } from '../app/features/itc-extractor/store/reducers/xlsx-reading.reducer';
import { ItcDateFilteringReducer } from '../app/features/itc-date-filter/store/reducers/itc-date-filtering.reducer';
import { SynoptiqueFetchingReducer } from '../app/features/synoptique-drawer/store/reducers/synoptique-fetching.reducer';

export const rootReducer = combineReducers({
  xlsxData: XlsxReadingReducer,
  itcList: XlsxItcExtractingReducer,
  itcListFiltredByDate: ItcDateFilteringReducer,
  synoptique: SynoptiqueFetchingReducer
});
