import { useDispatch } from 'react-redux';

import { SynoptiqueFactory } from '../../../../domaine/synoptique';
import { Drawer } from '../../../../ports';
import { DataAccessor } from '../../../../ports/data-accessor';
import { SynoptiqueDrawingAction } from '../store/actions/synoptique-drawing.action';
import { SynoptiqueFetchingAction } from '../store/actions/synoptique-fetching.action';
import { SynoptiqueSection } from '../components/synoptique-section';

export const SynoptiqueDrawer = () => {
  const dispatch = useDispatch();
  // wait until the selected element is created
  setTimeout(() => {
    Drawer.initDrawingArea('#synoptique', '100%', '100%');
  }, 100);

  // wait untill the drawing area is initiated
  setTimeout(() => fetchSynoptiqueAndDraw(), 200);

  const fetchSynoptiqueAndDraw = () => {
    try {
      const synoptique = DataAccessor.getSynoptique();
      dispatch(SynoptiqueFetchingAction.succeeded(synoptique));
      drawSynoptique(synoptique);
    } catch (error) {
      dispatch(SynoptiqueFetchingAction.failed(error));
    }
  };

  const drawSynoptique = (synoptique) => {
    try {
      SynoptiqueFactory.draw(synoptique as any);
      dispatch(SynoptiqueDrawingAction.succeeded());
    } catch (error) {
      dispatch(SynoptiqueDrawingAction.failed(error));
    }
  };

  return <SynoptiqueSection />;
};
