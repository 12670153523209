import {
  Drawer,
  TextColor,
  TextOrientation,
  TextSize,
  TextWeight
} from '../../ports';
import { Infra } from './infra.interface';
import { Quai } from './quai.interface';

export interface Station extends Infra {
  name: string;
  quaiList: Quai[];
}

const draw = (station: Station) => {
  station.quaiList.forEach((quai) => {
    const { startPoint } = quai.segment;
    const { name } = station;
    const label = {
      text: name,
      position: { x: startPoint.x + 10, y: startPoint.y + 10 },
      style: {
        size: TextSize.tertiary,
        fontWeight: TextWeight.secondary,
        textOrientation: TextOrientation.secondary,
        fontColor: TextColor.secondary
      }
    };
    Drawer.drawRectangle(
      {
        startPoint: { x: startPoint.x + 5, y: startPoint.y + 5 },
        width: 5,
        height: 5
      },
      label
    );
  });
};

const StationFactory = { draw };

export { StationFactory };
