export enum DrawColor {
  primary = 'black',
  secondary = 'red',
  tertiary = '#e8e8e8'
}

export enum DrawWidth {
  primary = 2,
  secondary = 3,
  tertiary = 6
}

export enum TextColor {
  primary = 'black',
  secondary = 'red',
  tertiary = '#e8e8e8'
}

export enum TextWeight {
  primary = 500,
  secondary = 700
}

export enum TextOrientation {
  secondary = 'horizontal-tb',
  primary = 'vertical-lr'
}

export enum TextSize {
  primary = 0.5,
  secondary = 0.65,
  tertiary = 0.8
}
