export interface AnimationStyle {
  width?: number;
}

export interface AnimationEffectStyle {
  fromStyle: AnimationStyle;
  toStyle: AnimationStyle;
}

export enum AnimationEvent {
  hover = 'hover'
}

export interface Animation {
  type: AnimationEvent;
  style: AnimationEffectStyle;
}
