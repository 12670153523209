import { useDispatch, useSelector } from 'react-redux';

import { RangeDatePicker } from '../components/range-date-picker';
import { ItcDateFilteringAction } from '../store/actions/itc-date-filtering.action';
import { filterByDateRange } from './date-filter-handler';

export const ItcDateFilter = () => {
  const itcListState = useSelector((state: any) => state.itcList);

  const dispatch = useDispatch();
  const filterItcList = (startDate, endDate) => {
    if (itcListState) {
      try {
        const itcList = itcListState.value;
        const filtredItcList = filterByDateRange(itcList, {
          startDate,
          endDate
        });
        dispatch(ItcDateFilteringAction.succeeded(filtredItcList));
      } catch (error) {
        dispatch(ItcDateFilteringAction.failed(error));
      }
      return;
    }
    dispatch(
      ItcDateFilteringAction.failed(new Error('itc state value is undefined'))
    );
  };

  return (
    <RangeDatePicker
      onDateRangePicked={(startDate, endDate) =>
        filterItcList(startDate, endDate)
      }
    ></RangeDatePicker>
  );
};
