import { XlsxItcExtractingAction } from '../actions/xlsx-itc-extracting.action';
import { State, Action, Status } from '../../../../../store';

const initialState = { status: Status.NotPerformed, value: '' };
export const XlsxItcExtractingReducer = (
  state: State = initialState,
  action: Action
): State => {
  const { type, payload } = action;
  switch (type) {
    case XlsxItcExtractingAction.succeeded(payload).type:
      return { ...state, ...{ status: Status.Succeeded, value: payload } };

    case XlsxItcExtractingAction.failed(payload).type:
      return {
        ...state,
        ...{ status: Status.Failed, value: payload }
      };

    default:
      return state;
  }
};
